<template>
  <div class="admin">
    <div v-if="error" class="alert alert-danger alert-dismissible fade show" role="alert">
      {{ error }}
    </div>
    <div v-if="isEdit">
      <b-container class="w-50">
        <b-row class="mt-3">
          <b-col>
            <label class="form-label">Username</label>
            <input type="text" class="form-control" id="InputUsername" v-model="curuser.username" @focus="trimiInputUser()">
            <div class="form-text text-danger" v-if="username_error">{{ username_error }}</div>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <label for="InputPassword1" class="form-label">Password</label>
            <input type="password" class="form-control" id="InputPassword1" v-model="curuser.password" @focus="trimiInputPass()">
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <label class="form-check-label" for="selectInput">Type</label>
            <select class="form-control" id="selectInput" v-model="curuser.apitype">
              <option value='' :selected="!curuser.apitype"> -- select a type -- </option>
              <option value='admin' :selected="curuser.apitype=='admin'">Admin</option>
              <option value='panda' :selected="curuser.apitype=='panda'">Panda</option>
            </select>
          </b-col>
        </b-row>
        <b-row class="mt-3">
          <b-col>
            <label for="InputNotes" class="form-label">Notes</label>
            <input type="text" class="form-control" id="InputNotes" v-model="curuser.notes">
          </b-col>
        </b-row>
        <b-row class="text-center mt-5">
          <b-col cols="6">
            <button class="btn btn-primary" @click="cancelUser()">Cancel</button>
          </b-col>
          <b-col cols="6">
            <button class="btn btn-success" @click="saveUser()">Submit</button>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-else>
      <div v-if="userList.length>0" class="container-fluid">
        <table class="table table-striped table-hover table-sm">
          <thead>
            <tr>
              <th>Username</th>
              <th>Type</th>
              <th class="w-50 text-center"><b-icon icon="person-plus-fill" variant="success" font-scale="2" @click="addUser()"></b-icon></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="o in userList" :key="o.id" @click="editUser(o)">
              <td>{{ o.username }}</td>
              <td>{{ o.apitype }}</td>
              <td>{{ o.notes }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Admin",
  data() {
    return {
      token: '',
      user: null,
      userList: [],
      curuser: null,
      newuser: {
        username: ' ',
        password: '',
        apitype: '',
        notes: '',
      },
      username_error: '',
      error: '',
      isEdit: false,
      pageleave: false,
    };
  },
  mounted() {
    const mytoken = window.localStorage.getItem('token');
    if ((mytoken == undefined) || !mytoken) {
      this.$router.push("/login");
    }
    this.token = mytoken;
    this.user = JSON.parse(window.localStorage.getItem('user'));
    if (this.user.apitype != 'admin') {
      alert("You don't have permission to do this function");
      this.$router.push("/login");
    }
    this.initData();
  },
  methods: {
    trimiInputUser() {
      this.curuser.username = this.curuser.username.replace(/\s/g, '');
    },
    trimiInputPass() {
      this.curuser.password = this.curuser.password.replace(/\s/g, '');
    },
    cancelUser() {
      this.isEdit = false;
    },
    addUser() {
      this.isEdit = true;
      this.curuser = JSON.parse(JSON.stringify(this.newuser));
      this.curuser.password = '';
    },
    editUser(o) {
      this.isEdit = true;
      this.curuser = JSON.parse(JSON.stringify(o));
      this.curuser.password = '';
    },
    saveUser(o) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      if ((this.curuser.id != undefined) && this.curuser.id) {
        bodyFormData.append("id", this.curuser.id);
      }
      if ((this.curuser.username != undefined) && this.curuser.username) {
        bodyFormData.append("username", this.curuser.username);
      } else {
        alert("Please input username");
        return;
      }
      if ((this.curuser.password != undefined) && this.curuser.password) {
        bodyFormData.append("password", this.curuser.password);
      }
      if ((this.curuser.apitype != undefined) && this.curuser.apitype) {
        bodyFormData.append("apitype", this.curuser.apitype);
      }
      if ((this.curuser.notes != undefined) && this.curuser.notes) {
        bodyFormData.append("notes", this.curuser.notes);
      }
      var that = this;

      this.$axios({
        method: "post",
        url: "/Api/updateUser",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.code == 0) {
              that.isEdit = false;
              that.initData();
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          that.error = error;
        });
    },
    beforeRouteLeave(to, from, next) {
      if (this.pageleave) {
        // Perform actions before leaving the page
      }
      next();
    },
    initData() {
      this.getUserLists();
    },
    getUserLists() {
      if (!this.token) {
        this.$router.push("/login");
        return;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      var that = this;

      this.$axios({
        method: "post",
        url: "/Api/userList",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.code == 0) {
              if (rt.data.userList) {
                that.userList = rt.data.userList;
              } else {
                that.userList = [];
              }
            } else if (rt.message != undefined) {
              alert(rt.message);
              that.$router.push("/login");
            }
          }
        })
        .catch(function (error) {
          that.error = error;
          that.$router.push("/login");
        });
    },
  }
}
</script>
<style scoped>
.toleft {
  text-align: left;
}
</style>